<template>
  <div
    class="date-select"
    v-click-outside="blurList"
    :class="{
      'date-select--focus': focused || opened,
      'date-select--filled': singleSelected,
      'date-select--input': search.length || focused,
      'date-select--drop': opened,
      'date-select--drop-top': listTop,
    }"
  >
    <div class="date-select__container">
      <label class="date-select__title" v-if="title" :for="singleSelected">
        {{ title }}
        <span>{{ required ? "*" : "" }}</span>
      </label>
      <span class="date-select__field-container">
        <input
          ref="input"
          :id="singleSelected"
          :value="formatDate(singleSelected) || emptyPlaceholder"
          :placeholder="title"
          class="date-select__field"
          readonly
          @blur="blur"
          @focus="focus"
        />
        <button v-if="singleSelected" class="date-select__clear" type="button" @click="clean">
          <IconComponent category="default" name="close" />
        </button>
        <button class="date-select__action" tabindex="-1" type="button" @click="toggle">
          <IconComponent category="vuesax-bold" name="calendar" />
        </button>
      </span>
    </div>
    <ClientOnly>
      <div class="date-select__drop" v-if="opened">
        <DatePicker
          v-model="singleSelected"
          :first-day-of-week="2"
          :is-range="range"
          :mode="mode"
          locale="ru"
          title-position="left"
          trim-weeks
          @input="handleInput"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import moment from "moment";
import IconComponent from "components/IconComponent.vue";
import ClientOnly from "vue-client-only";

export default {
  name: "DateComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default() {
        return "date";
      },
    },
    emptyPlaceholder: {
      type: String,
      default() {
        return "Все";
      },
    },
    range: Boolean,
    required: Boolean,
    value: [Object, Date],
  },
  data() {
    return {
      singleSelected: null,
      search: "",
      opened: false,
      listTop: false,
      scrollTimeout: undefined,
      focused: false,
    };
  },
  watch: {
    value() {
      this.singleSelected = this.value;
    },
    opened() {
      if (this.opened) {
        this.$nextTick(() => {
          this.calculateListPosition();
        });
      }
    },
  },
  mounted() {
    const placeholder = this.$refs.input.getAttribute("placeholder");
    this.$refs.input.setAttribute("size", placeholder.length - 2 + "");
    this.singleSelected = this.value;

    window.addEventListener("scroll", () => {
      if (this.opened) {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
          this.$nextTick(() => {
            this.calculateListPosition();
          });
        }, 200);
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", () => {});
  },
  methods: {
    formatDate(date) {
      const format = "DD.MM.YYYY";
      if (date) {
        if (date instanceof Date) {
          return moment(date).format(format);
        }
        return moment(date.start).format(format) + " - " + moment(date.end).format(format);
      }
      return date;
    },
    calculateListPosition() {
      if (this.$refs.list) {
        const height = this.$refs.list.$el.clientHeight;
        const top = this.$refs.list.$el.getBoundingClientRect().top;
        if (top + height > window.innerHeight) {
          this.listTop = true;
          return;
        }
        this.listTop = false;
      }
    },
    clean() {
      this.singleSelected = null;
      this.$emit("input", null);
    },
    /**
     * Обработка события выбора
     */
    select() {},
    toggle() {
      // Переключение состояния Input
      if (this.opened || this.focused) {
        this.blurList();
      } else {
        this.focus();
      }
    },
    /**
     * Фокус на input
     */
    focus() {
      this.$refs.input.focus();
      this.focused = true;
      this.opened = true;
    },
    /**
     * Расфокус на input
     */
    blur() {
      this.focused = false;
    },
    /**
     * Убрать список
     */
    blurList() {
      this.opened = false;
    },
    handleInput(e) {
      this.$emit("input", e);
      this.blurList();
    },
  },
  components: {
    IconComponent,
    ClientOnly,
    DatePicker: () => import("v-calendar/lib/components/date-picker.umd"),
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="stylus">
.date-select {
  display grid
  position relative
  grid-gap 5px

  &__container {
    display grid
    grid-gap 5px
    position relative
  }

  &__title {
    font-weight: 500;
    font-size: 0.75em;
    line-height: 14px;
    color: var(--dark-light);

    span {
      color: var(--red);
    }
  }

  &__action,
  &__clear {
    padding 15px
    background none
    border-radius 5px
    absolute right top bottom
    display inline-flex
    align-items center
    justify-content center
    border 1px solid transparent

    &:focus {
      border-color var(--main)
      box-shadow: 0 0 0 3px var(--main-o15);
    }

    ^[0]--drop &,
    ^[0]--drop-top & {
      z-index 2
    }

    .icon {
      width 14px
      height: 14px;

      svg path {
        fill var(--dark)
      }
    }
  }


  &__clear {
    padding 15px 10px
    transform translateX(-100%)

    ^[0]--drop &,
    ^[0]--drop-top & {
      z-index 3
    }

    .icon svg path {
      stroke var(--dark)
    }
  }

  &__action {
    padding-left 5px
  }

  &__field {
    border-radius: 5px;
    padding 12px 15px
    width 100%
    outline none
    border none
    background none
    font-family var(--font-regular)
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: var(--dark);

    &::placeholder {
      font-weight: 500;
      font-size: 1em;
      line-height: 26px;
      color: var(--dark);
      opacity: 0.3;
    }

    &::-ms-input-placeholder {
      font-weight: 500;
      font-size: 1em;
      line-height: 26px;
      color: var(--dark);
      opacity: 0.3;
    }
  }

  &__field-container {
    position relative
    display flex
    background: var(--white);
    border: 1px solid var(--gray-dark);
    border-radius 5px
    height 50px

    ^[0]--drop-top & {
      border-radius 0 0 5px 5px
    }

    ^[0]--drop & {
      border-radius 5px 5px 0 0
    }

    ^[0]--focus & {
      border: 1px solid var(--main);
      box-shadow: 0 0 0 3px var(--main-o15);
    }

    ^[0]--error & {
      border: 1px solid var(--red);
      box-shadow: 0 0 0 3px var(--red-o15);
    }
  }

  &__drop {
    absolute bottom left
    max-width calc(100% + 2px)
    transform translateY(calc(100% - 1px))
    z-index 10
    box-shadow: 0 0 0 3px var(--main-o15);
    border-radius 0 0 5px 5px
    clip-path inset(0 -3px -3px -3px)
    border 1px solid var(--main)

    .vc-container {
      font-family var(--font-regular)
      border-radius 0 0 5px 5px
      border none

      .vc-title {
        text-transform: capitalize;
      }
    }
  }
}
</style>
