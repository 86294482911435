<template>
  <main class="page page-press">
    <BreadcrumbsComponent title="Пресс-центр" />
    <div class="page__container">
      <div class="page__content">
        <div class="filters filters--col-4">
          <InputComponent
            v-model.trim="filterForm.title.value"
            :errors="filterForm.title.errors"
            autofocus
            title="Название"
            placeholder="Введите название"
            @input="debounceFilter"
          >
            <template v-slot:action>
              <IconComponent category="vuesax-bold" name="search-normal" />
            </template>
          </InputComponent>
          <DateComponent
            v-model="filterForm.published_at.value"
            emptyPlaceholder="Любая"
            mode="date"
            @input="filter"
            range
            title="Дата публикации"
          />
          <SelectComponent
            v-model="filterForm.type.value"
            :errors="filterForm.type.errors"
            :options="news_types"
            @input="filter"
            label-name="name"
            title="Тип"
            placeholder="Любой"
          />
          <SelectComponent
            v-model="filterForm.category.value"
            :errors="filterForm.category.errors"
            :options="news_categories"
            multiple
            @input="filter"
            label-name="title"
            title="Категория"
            placeholder="Любая"
          />
        </div>
        <span class="page-press__total">Всего статей: {{ total }}</span>
        <ul class="page-press__list">
          <li v-for="(article, i) in news" :key="i">
            <ListPressItem :data="article" />
          </li>
        </ul>
        <PaginationComponent
          :first="first"
          :page="page"
          :total="Math.ceil(total / first)"
          :totalItems="total"
          @paginate="paginate"
        />
      </div>
    </div>
  </main>
</template>
<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import DateComponent from "components/inputs/DateComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import PaginationComponent from "components/PaginationComponent.vue";
import ListPressItem from "components/ListPressItem.vue";
import moment from "moment";
import NEWS_LIST_PAGE from "gql/pages/NewsListPage.graphql";

let _query = {};
const dateFormat = "YYYY-MM-DD HH:mm:ss";

function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}

function collectVariables() {
  let variables = {};
  if (_query.page) {
    variables.page = _query.page;
  }
  if (_query.title) {
    variables.title = _query.title;
  }
  if (_query.type) {
    variables.type = _query.type;
  }
  if (_query.title) {
    variables.title = _query.title;
  }
  if (_query.category) {
    if (typeof _query.category === "number") {
      variables.category = [_query.category];
    }
    if (typeof _query.category === "object") {
      variables.category = _query.category.map((c) => parseInt(c));
    }
  }
  if (_query.publushed_at_start_date) {
    variables.publushed_at_start_date = _query.publushed_at_start_date;
  }
  if (_query.published_at_end_date) {
    variables.published_at_end_date = _query.published_at_end_date;
  }
  return variables;
}

const _first = 8;

export default {
  name: "PressListPage",
  async asyncData({ apollo, store, route }) {
    _query = parseParams(route.query);
    let variables = collectVariables();
    await apollo.defaultClient
      .query({
        query: NEWS_LIST_PAGE,
        variables: {
          first: _first,
          ...variables,
        },
      })
      .then(({ data }) => {
        store.dispatch("news/save", data);
      })
      .catch(() => {});
  },
  data() {
    _query = parseParams(this.$route.query);
    const news_types = [
      {
        id: 2,
        name: "Анонс",
      },
      {
        id: 1,
        name: "Новость",
      },
    ];
    const categories = this.$store.state.news_categories;
    let variables = collectVariables();
    let published_at = null;
    if (variables.publushed_at_start_date && variables.published_at_end_date) {
      published_at = {
        start: moment(variables.publushed_at_start_date).toDate(),
        end: moment(variables.published_at_end_date).toDate(),
      };
    }
    let type = null;
    if (variables.type) {
      type = news_types.find((t) => t.id === variables.type);
    }
    let category = [];
    if (variables.category) {
      category = variables.category.map((c) => categories.find((v) => v.id === parseInt(c)));
    }
    return {
      page: variables.page || 1,
      first: _first,
      news_types,
      filterForm: {
        title: {
          value: variables.title,
          defaultValue: null,
          errors: [],
        },
        published_at: {
          value: published_at,
          defaultValue: null,
          errors: [],
        },
        type: {
          value: type,
          defaultValue: null,
          errors: [],
        },
        category: {
          value: category,
          defaultValue: [],
          errors: [],
        },
      },
      debounceInterval: null,
    };
  },
  computed: {
    news_categories() {
      return this.$store.state.news_categories;
    },
    news() {
      return this.$store.state.news.news_paginate.data;
    },
    total() {
      return this.$store.state.news.news_paginate.paginatorInfo.total;
    },
  },
  methods: {
    paginate(e) {
      this.page = e;
      this.fetch();
    },
    filter() {
      this.page = 1;
      this.fetch();
    },
    debounceFilter() {
      clearInterval(this.debounceInterval);
      this.debounceInterval = setTimeout(this.filter, 500);
    },
    getPushStateLink() {
      let link = location.origin + location.pathname;

      function getParam(string) {
        return link.includes("?") ? "&" + string : "?" + string;
      }

      if (this.page && this.page !== 1) link += getParam("page=" + this.page);
      if (this.filterForm.title.value) link += getParam("title=" + this.filterForm.title.value);
      if (this.filterForm.category.value) {
        this.filterForm.category.value.forEach((c) => {
          link += getParam("category=" + c.id);
        });
      }
      if (this.filterForm.type.value) link += getParam("type=" + this.filterForm.type.value.id);
      if (this.filterForm.published_at.value) {
        link += getParam(
          "publushed_at_start_date=" + moment(this.filterForm.published_at.value.start).format(dateFormat)
        );
        link += getParam(
          "published_at_end_date=" + moment(this.filterForm.published_at.value.end).format(dateFormat)
        );
      }
      return link;
    },
    fetch() {
      let category = this.filterForm.category.value.map((c) => c.id);
      let variables = {
        first: this.first,
        page: this.page,
        title: this.filterForm.title.value,
        category: category ?? [],
        type: this.filterForm.type.value?.id,
      };
      if (this.filterForm.published_at.value) {
        variables["published_at_start_date"] = moment(this.filterForm.published_at.value.start).format(
          dateFormat
        );
        variables["published_at_end_date"] = moment(this.filterForm.published_at.value.end).format(
          dateFormat
        );
      }
      history.pushState({}, null, this.getPushStateLink());
      this.$apollo
        .query({
          query: NEWS_LIST_PAGE,
          variables,
        })
        .then(({ data }) => {
          this.$store.dispatch("news/save", data);
        })
        .catch(() => {});
    },
  },
  metaInfo: {
    title: "Пресс-центр",
  },
  components: {
    ListPressItem,
    PaginationComponent,
    SelectComponent,
    DateComponent,
    IconComponent,
    InputComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.page-press {
  &__total {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 22px;
    color: var(--dark);
  }

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 30px
    +below(1100px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(860px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(580px) {
      grid-template-columns 1fr
    }
  }

  & .page__content {
    grid-gap 30px
  }
}
</style>
